@import '../../../styles/settings';

.teamHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0rem 2rem;
    
    @include breakpoint(sm) {
        flex-direction: row;
        align-items: flex-end;
    }
}

.theTeamText {
    font-family: 'FT System';
    font-weight: 900!important;
    margin-bottom: 0;
    font-style: normal;
    font-size: 3.5rem;
    line-height: 125%;
    letter-spacing: -0.03em;
    color: #101828;
}

.teamMembers {
    display: flex;
    overflow-y: hidden;
    cursor: -webkit-grab; 
    cursor: grab;
}

.teamMembers::-webkit-scrollbar {
    display: none;
}

.teamMembers.js-dragging {
    cursor: -webkit-grabbing; 
    cursor: grabbing;
}

.cardsList {
    width: fit-content;
}

ol {
    list-style-type: none;
}

.card {
    width: 25rem;
    margin-top: 1rem;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    user-select: none;
}

.facts {
    margin-left: 1rem;
    list-style-type: circle;
}

.workPosition {
    color: #A82AAB;
    font-size: 18px;
}

.scrollBtnGroup {
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollBtn {
    border: 1px solid #EAECF0;
    border-radius: 14px;
}

.card-img{
    border-radius: 1em;
}

@media only screen and (max-width: 600px) {
    .card {
        width: 20rem;
        margin-top: 1rem;
        margin-left: 0.6rem;
        margin-right: 0.6rem;
    }

    .theTeamText {
        font-size: 2.4rem;
    }
    
    .scrollBtn {
        border: 1px solid #EAECF0;
        border-radius: 15px;
        height: 3.5rem;
        width: 3.5rem;
    }
}