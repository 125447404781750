input:focus {
    border-color: #A82AAB !important;
    box-shadow: 0px 0px 0px 4px rgb(168 42 171 / 30%), 0px 1px 2px rgb(16 24 40 / 5%) !important;
}

.disabled {
    background-color: #F2F4F7 !important;
    color: #D0D5DD !important;
    border: none;
}

.errMsg{
    font-size: 14px!important;
    color: red!important; 
    margin-top: 0.35em!important;
}

.modalWrapper {
    background-clip: padding-box;
    background-color: white;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%;
    max-width: 500px;
    padding: 16px 24px 24px 24px;
    gap: 1em;
    margin: auto;
}

.modalHeader {
    border-bottom: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    padding-bottom: 0;
}

.modalBody {
    font-size: 14px;
}

.modalBody a {
    color: #667085;
}

.modalBody div {
    color: #667085;
    margin-bottom: 1em;
}

.modalTitle {
    color: #101828;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.02em;
    line-height: 28px;
    text-align: center;
}

.modalButton {
    padding: 10px 20px;
    border-radius: 20px;
}

.modalFooter {
    display: flex;
    flex-direction: column;
    gap: .8em;
}

.modalFooter a {
    color: #667085;
    font-size: 14px;
    margin-bottom: 20px;
}

.modalFooter .halfFtBtn{
    width: 47%;
    font-weight: bold;
}

.modalFooter .wholeFtBtn{
    width: 100%;
    font-weight: bold;
}