@use "sass:map";

/* Colors */
$black: #000000;
$white: #ffffff;

$gray-100: $black;
$gray-90: #101828;
$gray-80: #344054;
$gray-70: #667085;
$gray-60: #98A2B3;
$gray-50: #BABCC8;
$gray-40: #D0D5DD;
$gray-30: #EAECF0;
$gray-20: #F2F4F7;
$gray-10: #F9FAFB;

$accent: #A82AAB;
$alert: #F04438;

$aiz-1: #02044C;
$aiz-1-gradient: linear-gradient(180deg, #217BFF 0%, #F3AE3E 27.6%, #C0267D 58.85%, #02044C 100%);
$aiz-2: #0F46B1;
$aiz-2-gradient: linear-gradient(180deg, #FFCF54 0%, #FD5A36 26.04%, #DB3C96 52.6%, #0F46B1 100%);
$aiz-3: #B1EBFD;
$aiz-3-gradient: linear-gradient(180deg, #FD7817 0%, #D72411 17.19%, #5F4FCF 48.96%, #B1EBFD 100%);
$aiz-4: #003E3A;
$aiz-4-gradient: linear-gradient(180deg, #62F4C8 0%, #F3C03E 27.6%, #26AEC0 58.85%, #003E3A 100%);
$aiz-5: #007682;
$aiz-5-gradient: linear-gradient(180deg, #C5F462 0%, #F3E13E 22.92%, #26C092 58.33%, #007682 100%);
$aiz-6: #D8FA77;
$aiz-6-gradient: linear-gradient(180deg, #FFDA17 0%, #FF9432 19.27%, #88C43B 35.94%, #3CCC98 53.12%, #D8FA77 100%);
$aiz-7: #7C0016;
$aiz-7-gradient: linear-gradient(180deg, #FF9D56 0%, #FC4219 27.6%, #A219D2 58.85%, #7C0016 100%);
$aiz-8: #DB4D26;
$aiz-8-gradient: linear-gradient(180deg, #3F8DFF 0%, #5752DF 18.75%, #CB6291 43.23%, #DB8940 69.27%, #DB4D26 100%);
$aiz-9: #FFD597;
$aiz-9-gradient: linear-gradient(180deg, #FF96BC 0%, #D711C3 20.83%, #5DBDDB 48.96%, #FFD597 100%);
$aiz-10: #36025F;
$aiz-10-gradient: linear-gradient(180deg, #15DDF9 0%, #EA48FF 31.77%, #EE4B28 50%, #36025F 100%);
$aiz-11: #BE0E78;
$aiz-11-gradient: linear-gradient(180deg, #FFCD4B 0%, #FA5B38 29.69%, #FF5A82 59.9%, #BE0E78 100%);
$aiz-12: #FFAFD6;
$aiz-12-gradient: linear-gradient(180deg, #FF9696 0%, #D20580 20.83%, #539EF5 48.96%, #FFAFD6 100%);

$focus-box-shadow: 0px 0px 0px 4px rgba(168, 42, 171, 0.3);

/* Background variants */
// Setting background variants based on gray colors to define background and text colors
$brackgrounds-types : 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;
$gray : (
    100: $black,
    90: $gray-90,
    80: $gray-80,
    70: $gray-70,
    60: $gray-60,
    50: $gray-50,
    40: $gray-40,
    30: $gray-30,
    20: $gray-20,
    10: $gray-10
);

@for $i from 1 through length($brackgrounds-types) {
    $bg-type: nth($brackgrounds-types, $i);
    .bg-#{$bg-type} {
        background-color: map.get($gray, $bg-type);

        * {
            @if ($bg-type < 50) {
                color: $gray-90;
            } @else {
                color: $white;
            }
        }
    }
}

/* Radius */
$radius-sm: 8px;
$radius-md: 16px;
$radius-lg: 24px;

/* Breakpoints ( from bootstrap ) */
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

@mixin breakpoint($point, $type: min) {
    // mixin that prints the media query for each of the bootstrap breakpoints and has option to use min-width or max-width
    $breakpoint-type : min-width;
    @if $type == max {
        $breakpoint-type : max-width;
    }

    @if $point == xs {
        @media only screen and ($breakpoint-type: $breakpoint-xs) { @content; }
    }
    @else if $point == sm {
        @media only screen and ($breakpoint-type: $breakpoint-sm) { @content; }
    }
    @else if $point == md {
        @media only screen and ($breakpoint-type: $breakpoint-md) { @content; }
    }
    @else if $point == lg {
        @media only screen and ($breakpoint-type: $breakpoint-lg) { @content; }
    }
    @else if $point == xl {
        @media only screen and ($breakpoint-type: $breakpoint-xl) { @content; }
    }
    @else if $point == xxl {
        @media only screen and ($breakpoint-type: $breakpoint-xxl) { @content; }
    }
}



/* Convert to rem */

// Converting px to rem with a base of 16px
@function rem($px) {
    @return #{$px / 16}rem;
}
