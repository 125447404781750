@import '../../../styles/settings';


.tabnav {
    margin: 0;
    padding: 0;
    
    li {
        display: flex;
        flex-direction: row;
        height: var(--progress-barr-size);

        margin: 1.5rem;
        width: 100%;
        border: none;
        outline: none;
        text-align: left;
        cursor: pointer;
        transition: 0.3s;
        
        p {
            font-size: 2.4vw;
            font-style: normal;
            line-height: 125%;
            letter-spacing: -0.03em;
            color: $gray-40;
        }

        #progressbar {
            width: 0.5rem;
            height: 0%;
            border-radius: 30px;
            overflow: hidden;
            visibility: hidden;
        }
        
        /* Create an active/current "tab button" class */
        &.active {
            p {
                color: $gray-90;
            }
            
            #progressbar {
                visibility: visible;
            }
        }
    }
}

/* Progress bar */

.progressBarWrapper1 {
    width: 0.5rem;
    margin-right: 1.3rem;
    border-radius: 30px;

    .active > & {
        transition: none;
        background-color: $gray-20;
    }
}

.progressBarWrapper2{
    display: none;
}

.progress {
    height: 0;
    width: 0;
    visibility: hidden;
    
    &__bar {
        min-width: 100vw;
        min-height: var(--progress-barr-size);
        background: linear-gradient(180deg, #217BFF 0%, #F3AE3E 27.6%, #C0267D 58.85%, #02044C 100%);
    }
}

.reducedBar{
    height: 1rem;
    border-radius: 30px;
    background-color: $gray-20;
    width: 6rem;
}

.TabContent img {
    height: inherit;
}

.tabCenter {
    margin-top: 2rem;
    position: relative !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    div {
        align-self: center;
    }
}


#img16 {
    transition: width 0.3s, height 0.3s;
    width: 28vw;
}

#img17 {
    transition: width 0.3s, height 0.3s;
    height: 15vw;
    margin-left: -18vw;
    width: max-content;
    position: relative;
}

#img24 {
    transition: width 0.3s, height 0.3s;
    width: 19vw;
    position: relative;
    margin-left: 18vw;
    margin-bottom: -4vw;
    transform: translateY(-4vw);
}

#img21 {
    transition: width 0.3s, height 0.3s;
    position: relative;
    height: 21vw;
    width: max-content;
    margin-left: 8rem;
    margin-top: 1.5rem;
}

#img22 {
    transition: width 0.3s, height 0.3s;
    position: relative;
    height: 13vw;
    margin-left: -26vw;
}


@include breakpoint(md) {
    .motivation__title {
        margin-left: 3rem;
    }
}


@media only screen and (max-width: 1024px) {
    :root {
        --progress-barr-size: 6rem;
    }

    .tabnav li p {
        font-size: 1.5rem;
        max-width: 90%;
    }

    #img17 {
        transition: width 0.3s, height 0.3s;
        height: 16vw;
        margin-left: -18vw;
        width: max-content;
        position: relative;
    }

    #img24 {
        transition: width 0.3s, height 0.3s;
        width: 20vw;
        position: relative;
        margin-left: 18vw;
        margin-bottom: -4vw;
        transform: translateY(-4vw);
    }
}

@media only screen and (max-width: 700px) {
    .progress {
        height: 1rem;
        width: 15px;
        border-radius: 10px;
        visibility: visible;
    }

    .tabs {
        display: flex;
        flex-direction: column-reverse;

        > div {
            margin-left: 0;
        }
        
        .tabnav {
            margin: 0;
            padding: 0;
        }
    }

    .tabnav li {
        display: none;
        visibility: hidden;
        transition: none;
        max-width: 90%;
        
        &.active {
            display: flex;
            visibility: visible;
            align-items: center;
            flex-direction: column-reverse;
            margin-top: 2rem;
            padding-inline: 1rem;
            transition: none;
            text-align: center;
            align-self: center !important;
        }

        #progressbar {
            height: 1rem;
            width: 0%;
            max-width: 20vw;
        }
    }



    .active>.progressBarWrapper2 {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 10rem;
        height: 0.8rem;
        margin-right: 1.3rem;
        border-radius: 30px;
        background-color: transparent;
    }

    .progressBarWrapper1{
        display: none;
    }

    .progress__bar {
        min-width: 20vw;
        background: $aiz-1-gradient;
    }

    #img16 {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    #img16>img {
        width: 19rem;
    }

    #img17 {
        position: relative;
        height: 11rem;
        width: max-content;
        margin-left: -25vw;
    }

    #img24 {
        position: relative;
        transform: translateY(-50px);
        width: 13rem;
        margin-left: 13rem;
        margin-bottom: -50px;
    }

    #img21 {
        position: relative;
        height: 12rem;
        width: max-content;
        align-self: flex-end;
        padding-right: 2rem;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }

    #img22 {
        position: relative;
        height: 8rem;
    }

}