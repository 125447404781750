@font-face {
    font-family: 'FT System';
    src: url(../assets/fonts/FTSystem-BlankRegular.ttf);
    font-display: swap;
}

@font-face {
    font-family: 'FT System Bold';
    src: url(../assets/fonts/FTSystem-BlankBold.ttf);
    font-display: swap;
}

* {
    font-family: 'FT System';
    color: $gray-80;

    .darkBg & {
        color: $white;
    }
}

h1,
h2,
h3,
h4,
h5 {
    font-style: normal;
    font-weight: 700;
    color: $gray-90;
    
    .darkBg & {
        color: $white;
    }
}

h1, .h1 {
    font-size: rem(60);
    line-height: 125%;
}

h2, .h2 {
    font-size: rem(40);
    line-height: 125%;
}

h3, .h3 {
    font-size: rem(24);
    line-height: 125%;
}

h4, .h4 {
    font-size: 1.25rem;
    line-height: 125%;
}

h5, .h5 {
    font-size: 1.125rem;
    line-height: 125%;
}

.h-section {
    display: block;
    margin-left: 0;
    text-align: center;
    font-size: 3.5rem;
    
    @include breakpoint(lg) {
        display: inline-block;
        font-size: 4rem;
    }

    @include breakpoint(xxl) {
        font-size: 4vw;
    }
    
}

.fs-lg {
    // large font size for body copy
    font-size: 18px;
}

.fs-md {
    // medium font size for body copy
    font-size: 18px;
}

.fs-sm {
    // small font size for body copy
    font-size: 18px;
}

.fs-xs {
    // xsmall font size for body copy
    font-size: 18px;
}

b, strong {
    font-weight: 700;
}