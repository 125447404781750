@import '../../../../styles/settings';

input:focus {
    border-color: #A82AAB !important;
    box-shadow: 0px 0px 0px 4px rgb(168 42 171 / 30%), 0px 1px 2px rgb(16 24 40 / 5%) !important;
}

.modalHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: none;
    gap: 5px;
    padding-bottom: 0px;
}

.modelContent {
    border-radius: 40px;
    padding: 10px 5px;
}

.modalTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.02em;
}

.modalDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: $gray-70;
}

.labelForm {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: $gray-80;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 5px;
}

.form-control {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;
    height: 48px;
    background: $white;
    border: 1px solid $gray-40;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.spaceLabels {
    margin-top: 20px;
}

.btnPassword {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px 14px;
    gap: 10px;
    height: 48px;
    width: 100%;
    border-radius: 20px !important;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    border: unset !important;
    border-color: unset !important;
    background: #101828;
    color: #FFFFFF;
}

.btnPassword:disabled {
    background: #F2F4F7;
    color: #D0D5DD;
}

.errorMessage {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: red;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 5px;
}