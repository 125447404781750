.contact {
    /*background-color: #101828;*/
    background: url("../../../assets/imgs/contactBg.png") no-repeat center;
    background-size: cover;
}

#contactUs {
    padding: 0rem 2rem;
}

.contact input {
    background-color: #101828;
    border: 1px solid #667085;
    color: white;
}

.contact input:focus {
    background-color: #101828;
    border: 1px solid #667085;
    color: white;
}

.contact textarea {
    background-color: #101828;
    border: 1px solid #667085;
    color: white;
    height: 100px;
}

.contact textarea:focus {
    background-color: #101828;
    border: 1px solid #667085;
    color: white;
}

#privacyPolicy {
    outline: 1px solid #667085;
}

.getInTouch {
    font-family: 'FT System';
    font-style: normal;
    line-height: 125%;

    text-align: center;
    letter-spacing: -0.03em;
}