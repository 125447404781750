:root {
	--progress-barr-size: 10vw;
}


html {
	max-width: 100vw;
	overflow-x: hidden;
}

.alingCenter {
	align-self: center !important;
}

.flexColumn {
	display: flex;
	flex-direction: column;
}

.md-gap {
	gap: 1.5rem;
}

.no-mrg-bt {
	margin-bottom: 0;
}

.md-mrg-bt {
	margin-bottom: 1.5rem;
}

.footerContact-1,
.footerContact-2 {
	display: flex;
	justify-content: space-between;
	margin: 2rem 5rem;
}

.rounded-pill {
	border-radius: 1rem !important
}

@media only screen and (max-width: 1024px) {
	#TPC {
		margin-left: -1rem !important;
	}

	.footerContact-2 {
		display: flex;
		flex-direction: column-reverse;
	}

	.footerContact-2>div {
		margin-bottom: 1rem !important;
	}
}

@media only screen and (max-width: 900px) {
	#MAC {
		display: flex;
		flex-direction: column;
	}

	.subscribeGroup {
		margin-left: -1rem;
	}

	#subscribeBtn div {
		margin-right: 0 !important;
	}

	#MAC span {
		margin: 0 !important;
		margin-bottom: 1rem !important;
	}

	.footerContact-1,
	.footerContact-2 {
		display: flex;
		flex-direction: column;
		margin: 2rem 1rem;
	}

	#subscribeBtn {
		display: flex;
		flex-direction: column;
	}

}

@media only screen and (max-width: 455px) {
	html {
		font-size: 14px !important;
	}
}

@media only screen and (max-width: 385px) {
	html {
		font-size: 12px !important;
	}
}