.footer {
    background-color: #101828;
    color: #D0D5DD;
    overflow: hidden;
}

.dailight {

    position: absolute;
    width: 97px;
    height: 20px;

    font-family: 'FT System';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #D0D5DD;
    opacity: 0.8;
    transform: translate(5px, -5px);
}

#stayUpToDate {
    position: absolute;

    color: #FFFFFF;
    /*transform: translate(820px, 20px);*/
}


#subscribeBtn input {
    background-color: #101828;
    border: 1px solid #667085;
    color: white;
    /*transform: translate(330px, 20px);*/
}

#subscribeBtn input:focus {
    background-color: #101828;
    border: 1px solid #667085;
    color: white;
}


#email_subscribe {
    background: rgba(255, 255, 255, 0.25);
    /*transform: translate(350px, 20px);*/
}

#TPC {
    color: #98A2B3;
}

#copyright {
    color: #98A2B3;
}

#icons {
    color: #98A2B3;
}

.floatingSticks {
    visibility: hidden;
    position: relative;
}

@media only screen and (max-width: 500px) {

    .floatingSticks {
        visibility: visible;
    }

    #stick1 {
        position: absolute;
        width: 9.16px;
        height: 206.07px;

        background: linear-gradient(180deg, #FFCD4B 0%, #FA5B38 29.69%, #FF5A82 59.9%, #BE0E78 100%);
        border-radius: 22.8972px;
        transform: translate(370px, -50px) rotate(167deg);
    }

    #stick2 {
        position: absolute;
        width: 9.16px;
        height: 206.07px;

        background: linear-gradient(180deg, #FD7817 0%, #D72411 17.19%, #5F4FCF 48.96%, #B1EBFD 100%);
        border-radius: 22.8972px;
        transform: translate(350px, 30px) rotate(-127deg);
    }

    #stick3 {
        position: absolute;
        width: 9.16px;
        height: 206.07px;

        background: linear-gradient(180deg, #FD7817 0%, #D72411 17.19%, #5F4FCF 48.96%, #B1EBFD 100%);
        border-radius: 22.8972px;
        transform: translate(350px, 330px) rotate(139.62deg);
    }

    .dailight {
        visibility: hidden;
        width: 0%;
    }
}