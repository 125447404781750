@import '../../../styles/settings';

.platformInner {
    position: relative;
    background: $gray-20;
    border-radius: 20px;
    overflow: hidden;
}

.platform {
    padding: 1.5rem;
}

.platformTitle {
    display: block;
    letter-spacing: -0.03em;
}

.plataformImageSize {
    width: 30px;
    height: 30px;
}

.textPart {
    color: $gray-70;
}

#floatingStick1 {
    position: absolute;
    width: 13.75px;
    height: 120.92px;
    top: 10px;
    background: $aiz-11-gradient;
    border-radius: 56.8801px;
    transform: matrix(0.91, 0.41, 0.41, -0.91, 0, 0);
    transform: translate(50px, -15px) rotate(10deg);
}

#floatingStick2 {
    position: absolute;
    width: 13.75px;
    height: 280px;
    background: $aiz-9-gradient;
    border-radius: 56.8801px;
    transform: matrix(-0.67, 0.74, 0.74, 0.67, 0, 0);
    transform: translate(120px, -50px) rotate(-45deg);
}

@media screen and (max-width: 600px) and (min-width: 300px) {
    #floatingStick1 {
        transform: matrix(0.91, 0.41, 0.41, -0.91, 0, 0);
        transform: translate(48px, -35px) rotate(24deg);
    }

    #floatingStick2 {
        height: 200px;
        transform: matrix(-0.67, 0.74, 0.74, 0.67, 0, 0);
        transform: translate(68px, -56px) rotate(-45deg);
    }

    .platform {
        padding: 0.7rem;
    }

    .platformTitle {
        transform: translate(0px, 60px);
        font-weight: bolder;
    }
}

@media screen and (max-width: 767px) and (min-width: 601px) {
    #floatingStick1 {
        transform: matrix(0.91, 0.41, 0.41, -0.91, 0, 0);
        transform: translate(48px, -35px) rotate(24deg);
    }

    #floatingStick2 {
        height: 200px;
        transform: matrix(-0.67, 0.74, 0.74, 0.67, 0, 0);
        transform: translate(68px, -56px) rotate(-45deg);
    }

    .platform {
        padding: 0.7rem;
    }

    .platformTitle {
        transform: translate(30px, 0px);
        font-weight: bolder;
    }
}