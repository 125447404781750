@mixin button {
    -webkit-appearance: none;
    appearance: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 14px 24px;
    border: 0;
    border-radius: $radius-md;
    background-color: $gray-90;
    
    color: $white;
    font-size: rem(16);
    line-height: rem(20);
    font-weight: 700;
    text-decoration: none;
    letter-spacing: -0.02em;
    white-space: nowrap;

    &:hover {
        background-color: $gray-80;
        color: $white;
    }
    
    &:focus {
        box-shadow: $focus-box-shadow;
    }

    &:active {
        color: $gray-40;
    }

    &:disabled {
        background-color: $gray-20;
        color: $gray-40;
        cursor: default;
    }

    /* Background dependent variants */
    .bg-100 &,
    .bg-90 &,
    &.light {
        background-color: $white;
        color: $gray-90;

        &:hover {
            background-color: $gray-30;
            color: $gray-90;
        }

        &:active {
            color: $gray-60;
        }

        &:disabled {
            background-color: $gray-80;
            color: $gray-60;
            cursor: default;
        }
    }
}

[type=button],
[type=submit] {
    @include button;
}

button,
a.button {
    @include button;

    /* Variants */
    &.secondary {
        background-color: transparent;
        color: $gray-90;
        border: 1px solid $gray-40;

        &:hover {
            background-color: $gray-20;
        }
        
        &:active {
            background-color: $gray-30;
            color: $gray-80;
        }

        &:disabled {
            background-color: $white;
            color: $gray-40;
            border-color: $gray-20;
            cursor: default;
        }

        .bg-100 &,
        .bg-90 &,
        &.light {
            color: $gray-10;
            border: 1px solid $gray-10;

            &:hover {
                background-color: $gray-80;
            }

            &:active {
                color: $gray-60;
            }
        }
    }

    &.tertiary {
        background-color: transparent;
        color: $gray-90;

        &:hover {
            background-color: $gray-20;
        }
        
        &:active {
            background-color: transparent;
            color: $gray-80;
        }

        &:disabled {
            color: $gray-40;
            cursor: default;
        }

        .darkBg & {
            color: $white;

            &:hover {
                background-color: $gray-90;
            }

            &:active {
                color: $gray-20;
            }
        }
    }
}