@import "../../../styles/settings";

#wrappCanvas {
    position: absolute;
    overflow: hidden;
    height: 100Vh;
    width: 100%;
    z-index: -1;
}

.elementCanvas {
    position: absolute;
    transition: top;
    width: 0.9vw;
    height: 38vh;
    min-width: 14px;
    min-height: 320px;
    border-radius: 10px;
}

.canvasElement {
    position: absolute;
}

#about {
    overflow-y: scroll;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1;
    height: 100vh;
    text-align: center;

    &.whiteBg {
        background: transparent;
        position: static;
    }
    
    &.darkBg {
        background: $black;
    }

    &::-webkit-scrollbar {
        display: none;
    }
    
    & {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    & > div {
        height: 101vh;
    }
}

.aboutWhite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    &__inner {
        margin: 20px;
        max-width: 950px;
        text-shadow: 0rem 0rem 4rem #ffffffcc;
        background-image: radial-gradient(white, white, white, #ffffffc9, #ffffff00, #00000000, #00000000);
        border-radius: 50%;
        padding: 4em;
    }

    h1 {
        font-size: 2rem;
        font-weight: 400;
        
        @include breakpoint(md) {
            font-size: 2.5rem;
        }
    }
}

.aboutDark {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
    .h1 {
        color: $white;
    }
    
    &__inner {
        font-size: 3.5rem;
        text-shadow: 0rem 0rem 2rem #000000cc;
        background-image: radial-gradient(black, black, black, #000000c9, #00000000, #00000000, #00000000);
        padding: 1em 5em;
        border-radius: 50%;
    }
}

@media only screen and (max-width: 700px) {
    .aboutWhite>div {
        padding: 3rem;
    }

}