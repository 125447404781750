@import '../../styles/settings';

.navbar {
	.nav-item .nav-link {
		color: $white;
		white-space: nowrap;

		&:hover {
			color: lighten($accent, 30%);
		}
	}

	.blurrer {
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		position: absolute;
		overflow: hidden;
		background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(0, 0, 0, 0.25);
		backdrop-filter: blur(2.5px);
		z-index: -500;
	}
	
	&.navbar-light {
		.blurrer {
			background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(255, 255, 255, 0.25);
		}
		
		.nav-item .nav-link {
			color: $black;

			&:hover {
				color: $accent;
			}
		}
	}	
}



@media only screen and (max-width: 767px) and (min-width: 601px) {
	nav .NavMenuList {
		display: none;
	}

	nav #elem-button {
		float: right;
	}
}

@media screen and (max-width: 600px) and (min-width: 481px) {
	nav .NavMenuList {
		display: none;
	}

	nav #elem-button {
		float: right;
	}
}


@media screen and (max-width: 480px) and (min-width: 300px) {
	nav .NavMenuList {
		display: none;
	}

	nav #elem-button {
		float: right;
	}
}